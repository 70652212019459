<template>

  <div>
        <v-card-text class=" align-center flex-wrap pb-0">
          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-5">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search"
              reverse
            ></v-text-field>
<!-- v-model="orderSourceFilter" -->
            <!-- <v-select
              label="Select Restaurant"
              v-model="NoteListTable.RestaurantId"
              :items="[
                { Id: 1, Name: 'CallCenter' },
                { Id: 2, Name: 'RestaurantApp' },
                { Id: 3, Name: 'Talabat' },
                { Id: 4, Name: 'Careem' },
                { Id: 5, Name: 'Other' },
              ]"
              item-text="Name"
              item-value="Name"
              outlined
              dense
              hide-details
              clearable
            ></v-select> -->
          <select-restaurant
            class="mb-6"
            v-model="RestaurantId"
          />
          </div>
        </v-card-text>
    <v-snackbar
      v-model="snackbar"
      timeout="1000"
      left
      bottom
    >
       <v-icon size="20" color="success">
         {{ icons.mdiCheckBold  }}
       </v-icon>Done

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  <v-card
 v-if="RestaurantId != null"
    class="app-invoice-editable "
  >

    <v-divider></v-divider>

    <!-- Add purchased products -->
    <div class="add-products-form pt-9 pb-10 px-8 ">
              <div class="add-products-header mb-2 d-none d-md-flex">
          <div class="px-5 flex-grow-1 font-weight-semibold ">
            <v-row>
              <v-col
                cols="7"
                md="7"
                class="d-flex justify-end"
              >
                <span>Content</span>
              </v-col>
              <v-col
                cols="4"
                md="4"
                class="d-flex justify-end"
              >
                <span>Header</span>
              </v-col>
              <v-col
                cols="1"
                md="1"
                class="d-flex justify-end"
              >
            <span>Id</span>
              </v-col>
            </v-row>
          </div>
          <div class="header-spacer"></div>
        </div>
      <div
        v-for="(NoteListTable, item) in NoteListTable"
        :key="item"
        class="single-product-form"
      >
        <v-card
          min-height = "100px"
          outlined
          class="d-flex"
        >
                  <!-- Item Actions -->
          <div class="d-flex flex-column item-actions rounded-0 pr-1  pl-1">
            <v-btn
            v-if="!disableEdit"
              icon
              small
              @click="deleteNote(NoteListTable.Id)"
            >
              <v-icon size="20" color="error">
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
            v-if="!disableEdit"
              icon
              small
              @click="onSubmit(NoteListTable)"
            >
              <v-icon size="20" color="success">
                {{ icons.mdiCheckBold  }}
              </v-icon>
            </v-btn>
          </div>
          <!-- Left Form -->
          <div class=" flex-grow-1">
            <v-row>
             <v-col
                cols="7"
                md="7"
                sm="12"
              >
                <v-textarea
                class="text-base pt-3 font-weight-semibold text-xl pl-4"
                  reverse
                  auto-grow
                  filled
                  full-width
                  rows="2"
                  row-height="20"
                  solo
                  style="direction: rtl;"
                  v-model="NoteListTable.Content"
                  :readonly="disableEdit"
                  :value="item.Content"
                  :background-color="$vuetify.theme.dark? '#413c62' :'#ffecb3'"
                  @change="onSubmit(NoteListTable)"
                  @input="val => { NoteListTable.Content }"
                 ></v-textarea>
                <!-- <v-text-field
                    auto-grow
                   rows="2"
                  row-height="20"
                  v-model="NoteListTable.Content"
                  outlined
                  dense
                  hide-details="auto"
                  :value="item.Content"
                  @input="val => { NoteListTable.Content }"
                ></v-text-field> -->
              </v-col>
              <v-col
                cols="4"
                md="4"
                sm="4"
              >
                <v-text-field
                reverse
                filled
                shaped
                  v-model="NoteListTable.Header"
                  class="text-base pt-3 font-weight-semibold text-2xl"
                  :readonly="disableEdit"
                  :value="item.Header"
                  :background-color="$vuetify.theme.dark? '#413c62' :'#ffecb3'"
                  @change="onSubmit(NoteListTable)"
                  @input="val => { NoteListTable.Header }"
                ></v-text-field>
              </v-col>

             <v-col
                cols="1"
                md="1"
                sm="2"
                class="pt-5"
              >
              <v-chip
                 class="ma-2"
                 outlined
                 color="warning"
               >
                 {{NoteListTable.Id}}
               </v-chip>

              </v-col>
            </v-row>
          </div>

        </v-card>
      </div>
      <div class="d-flex justify-end">
      <v-btn
        color="primary"
        class="mt-4"
        outlined
        @click="addNewItem"
      >
        Add Item
      </v-btn>
      </div>
    </div>
  </v-card>
  </div>
</template>
<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import SelectRestaurant from '@/views/Menu/Restaurant/SelectRestaurant.vue'
import { Create, Delete, Edit, GetNotes } from '@core/api/Note'
import {
mdiCheckBold,
mdiClose,
mdiCogOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiEyeOutline,
mdiPencilOutline,
mdiTrendingUp
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
    theme: {
    themes: {
      ...themeConfig.themes,
    },
    },
  components: {
    SelectRestaurant,
    AppCardActions,
  },
  setup() {
    const NoteListTable = ref([])
    const searchQuery = ref('')
    const RestaurantId = ref(null)
    const snackbar = ref(false)
    const isAddActive = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    const disableEdit = ref(true)
    const fetchNotes = () => {
    GetNotes({any:searchQuery.value, ResId:RestaurantId.value})
      .then(response => {
        // handle success
        response == '' ? (NoteListTable.value = []) : (NoteListTable.value = response)
      })
      .catch(error => {
        console.log(error)
      })
  }
    onMounted(() => {
      if (userRole === 'DEVELOPER' || userRole === 'ADMIN')
      {
        disableEdit.value = false
      }
      fetchNotes()
    })
    const isIssueDateMenuOpen = ref(false)
    const isDueDateMenuOpen = ref(false)
    const  deleteNote = (Id) => {
      Delete({ Id: Id }).then(res => {
        fetchNotes()
        snackbar.value = true
      })
    }
    const addNewItem = () => {
        const newNote = {
        Id: undefined,
        Header: ' ',
        Content: ' ',
        RestaurantId: RestaurantId.value,
      }
      Create(newNote).then(res => {
       fetchNotes()
      })
    }
    const onSubmit = Data => {
      Edit(Data).then( res => {
          snackbar.value = true
           })
    }


   watch([searchQuery, RestaurantId], () => {
     fetchNotes()
   },
     )

    return {
      disableEdit,
      userData,
      userRole,
      deleteNote,
      RestaurantId,
      snackbar,
      onSubmit,
      isAddActive,
      NoteListTable,
      searchQuery,
      fetchNotes,
      icons: {
        mdiClose,
        mdiCogOutline,
        mdiTrendingUp,
        mdiCheckBold,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
      isIssueDateMenuOpen,
      isDueDateMenuOpen,
      addNewItem,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-right: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
